import axios from '@/lib/axios'


export const ossAuth = (data) => {
    return axios.request({
        url: 'api/osstmp',
        data: data,
        method: 'post'
    });
};

//首页轮播图
export const carousel = (data) => {
    return axios.request({
        url: 'api/carousel',
        data: data,
        method: 'post'
    });
};

/**
 * 个人信息
 * 以下为type的类型
 * user:用户信息，
 * collectCountSql：商品收藏数量
 * browseCount：浏览记录数量
 * unPaymentCount：付款中数量
 * password：是否有支付密码
 * credit_line：授信额度
 * remaining：剩余额度，
 * applyStatus：授权状态
 * 其他则返回全部
 * @param {*} data 
 * @returns 
 */
export const userDetail = (data) => {
    return axios.request({
        url: 'api/userDetail',
        data: data,
        method: 'post'
    });
};

//地区
export const area = (data) => {
    return axios.request({
        url: 'api/area',
        data: data,
        method: 'post'
    });
};

// 获取授权跳转地址
export const getAuthUrl = (data) => {
    return axios.request({
        url: 'api/authUrl',
        data: data,
        method: 'post'
    });
};

// 发送短信
export const send = () => {
    return axios.request({
        url: 'api/sendSms',
        method: 'post'
    });
};

// 校验短信验证码
export const checkSmsCode = (data) => {
    return axios.request({
        url: 'api/checkValidation',
        data: data,
        method: 'post'
    });
};

// 设置密码
export const setPassword = (data) => {
    return axios.request({
        url: 'api/updatePaymentPwd',
        data: data,
        method: 'post'
    });
};

// 登录
export const login = (data) => {
    return axios.request({
        url: 'api/codeLogin',
        data: data,
        method: 'post'
    });
};

// 点击记录
export const clickLog = (data) => {
    return axios.request({
        url: 'api/log/click',
        data: data,
        method: 'post'
    });
};

// 配件公众号js配置
export const partConfig = (data) => {
    return axios.request({
        url: 'api/part/config',
        data: data,
        method: 'post'
    });
};

// 更新用户信息
export const userUpdate = (data) => {
    return axios.request({
        url: 'api/user/update',
        data: data,
        method: 'post'
    });
};

// 积分列表
export const integralList = (data) => {
    return axios.request({
        url: 'api/pointList',
        data: data,
        method: 'post'
    });
};

export const errorLog = (data) => {
    return axios.request({
        url: 'api/errorLog',
        data: data,
        method: 'post'
    });
};

// app登录
export const appLogin = (data) => {
    return axios.request({
        url: 'api/codeAppLogin',
        data: data,
        method: 'post'
    });
};

// 通联短信发送
export const allinSend = () => {
    return axios.request({
        url: 'api/sendVerificationCode',
        method: 'post'
    });
};

// 通联绑定手机号
export const allinBind = (data) => {
    return axios.request({
        url: 'api/mobileByBind',
        method: 'post',
        data
    });
};

// 文件上传到oss
export const uploadOss = (data) => {
    return axios.request({
        url: 'api/up_oss',
        method: 'post',
        data
    });
};


// oss授权访问签名(有效期600秒)

export const generateOssAccessUrl = (data) => {
    return axios.request({
        url: 'api/generateOssAccessUrl',
        method: 'post',
        data
    });
};

export const indexToy = () => {
    return axios.request({
        url: 'api/indexToy',
        method: 'post'
    });
}

// 商家主页
export const shopIndex = (data) => {
    return axios.request({
        url: 'api/supplierHome',
        method: 'post',
        data
    });
}