import axios from '@/lib/axios'

//添加收货地址
export const addressCreate = (data) => {
    return axios.request({
        url: 'api/addressCreate',
        data: data,
        method: 'post'
    });
};

//地址列表
export const addressList = (data) => {
    return axios.request({
        url: 'api/addressList',
        data: data,
        method: 'post'
    });
};

//修改地址
export const addressUpdate = (data) => {
    return axios.request({
        url: 'api/addressUpdate',
        data: data,
        method: 'post'
    });
};

//删除地址
export const addressDelete = (data) => {
    return axios.request({
        url: 'api/addressDelete',
        data: data,
        method: 'post'
    });
};

//地址详情
export const addressDetail = (data) => {
    return axios.request({
        url: 'api/addressDetail',
        data: data,
        method: 'post'
    });
};

//修改默认地址设置
export const setDefaultAddress = (data) => {
    return axios.request({
        url: 'api/setDefaultAddress',
        data: data,
        method: 'post'
    });
};

// 定位获取地址
export const location = (data) => {
    return axios.request({
        url: 'api/location',
        data: data,
        method: 'post'
    });
};

// 地址搜索
export const locationsBySearch = (data) => {
    return axios.request({
        url: 'api/locationsBySearch',
        data: data,
        method: 'post'
    });
};

// ip定位
export const locationsByIp = () => {
    return axios.request({
        url: 'api/locationsByIp',
        method: 'post'
    });
};

// 获取行政区划数据
export const getDistrict = (data) => {
    return axios.request({
        url: 'api/getDistrict',
        data: data,
        method: 'post'
    });
}