export default {
  namespaced: true,
  state: {
    address: "",
  },
  getters: {
    getaddress: (state) => state.address,
  },
  mutations: {
    setaddress(state, address) {
      state.address = address;
    },
  },
  actions: {
    doaddress(context, address) {
      context.commit("setaddress", address);
    },
  },
};
