import Vue from "vue";
import Vuex from "vuex";
import routerData from '@/store/module/routerData.js';
import user from '@/store/module/user.js';
import chat from '@/store/module/chat.js';
import wx from '@/store/module/wx.js';
import search from "./module/search";
import address from "./module/address"; 
import defaultData from "@/store/module/defaultData";
import area from "./module/area";
Vue.use(Vuex);

export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        routerData,
        user,
        chat,
        wx,
        search,
        address,
        defaultData,
        area,
    }
});
