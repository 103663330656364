import { getDistrict } from "@/api/address.js";
export default {
    namespaced: true,
    state: {
      cityMap: {},
    },
    mutations: {
      setCityMap(state, data) {
        state.cityMap = data;
      },
    },
    actions: {
        // 初始化城市名全称和简写映射
        initCityMap({commit, state}, reload=false) {
            return new Promise((resolve, reject)=>{
                if(JSON.stringify(state.cityMap) === '{}' || reload){
                    getDistrict({}).then(res=>{
                        if(res.code == 0){
                            let result = {};
                            let tmp = res.data.map(item => item.data);
                            tmp = tmp.reduce((prev, current) => prev.concat(current), []);
                            tmp.forEach(element => {
                                result[element['fullname']] = element['name'];
                            });
                            commit("setCityMap", result);
                            resolve(state.cityMap);
                        }
                    })
                }else{
                    resolve(state.cityMap);
                }
            })
            
        },
    },
  };
  