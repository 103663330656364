import { getLocation } from "@/lib/map.js";
import store from "@/store";
import { location } from "@/api/address.js";
export const getAppLocation = (cover=false) =>{
    getLocation().then(loc=>{
        if(loc){
            let params = {
                latitude: loc.lat,
                longitude: loc.lng
            };
            if(cover || store.state.wx.location.latitude == '0.0000000000'){
                location(params)
                .then(res => {
                    if (res.code == 0) {
                        store.commit('wx/setLocation', {
                            latitude: params.latitude,
                            longitude: params.longitude,
                            title: res.data?.location?.recommend || '设置地址',
                            address: res.data?.location?.recommend || '设置地址'
                        })
                    }
                })
            }
            
        }
    })
}