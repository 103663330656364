var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.$route.meta && _vm.$route.meta.showTabbar)?_c('div',{staticClass:"container"},[_c('div',{staticClass:"current-van-tabbar__placeholder"}),_c('van-tabbar',{attrs:{"fixed":true,"placeholder":false,"active-color":"#006AFA","inactive-color":"#999999","border":false},on:{"change":_vm.onChange},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},[_c('van-tabbar-item',{attrs:{"name":"index"},scopedSlots:_vm._u([{key:"icon",fn:function(props){return [_c('img',{staticClass:"tabbar-icon",attrs:{"src":props.active
              ? require('@/assets/images/tabbar/home1.png')
              : require('@/assets/images/tabbar/home2.png')}})]}}],null,false,1285633222)},[_c('div',{staticClass:"tabbar-text"},[_vm._v("首页")])]),_c('van-tabbar-item',{attrs:{"name":"customization"},scopedSlots:_vm._u([{key:"icon",fn:function(props){return [_c('img',{staticClass:"tabbar-icon",attrs:{"src":props.active
              ? require('@/assets/images/icon/customize-active.png')
              : require('@/assets/images/icon/customize.png')}})]}}],null,false,757124964)},[_c('div',{staticClass:"tabbar-text"},[_vm._v("定制")])]),_c('van-tabbar-item',{attrs:{"name":"chat"},scopedSlots:_vm._u([{key:"icon",fn:function(props){return [(_vm.unread_count > 0)?_c('van-badge',{staticClass:"badge",attrs:{"content":_vm.unread_count,"max":99}},[_c('img',{staticClass:"tabbar-icon",attrs:{"src":props.active
                ? require('@/assets/images/tabbar/chat1.png')
                : require('@/assets/images/tabbar/chat2.png')}})]):_c('img',{staticClass:"tabbar-icon",attrs:{"src":props.active
              ? require('@/assets/images/tabbar/chat1.png')
              : require('@/assets/images/tabbar/chat2.png')}})]}}],null,false,4124488402)},[_c('div',{staticClass:"tabbar-text"},[_vm._v("消息")])]),_c('van-tabbar-item',{attrs:{"name":"procurement"},scopedSlots:_vm._u([{key:"icon",fn:function(props){return [_c('img',{staticClass:"tabbar-icon",attrs:{"src":props.active
              ? require('@/assets/images/icon/purchase_list_active.png')
              : require('@/assets/images/icon/purchase_list.png')}})]}}],null,false,1386642934)},[_c('div',{staticClass:"tabbar-text"},[_vm._v("采购清单")])]),_c('van-tabbar-item',{attrs:{"name":"my"},scopedSlots:_vm._u([{key:"icon",fn:function(props){return [_c('img',{staticClass:"tabbar-icon",attrs:{"src":props.active
              ? require('@/assets/images/tabbar/my1.png')
              : require('@/assets/images/tabbar/my2.png')}})]}}],null,false,1110512390)},[_c('div',{staticClass:"tabbar-text"},[_vm._v("我的")])])],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }