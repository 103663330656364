import { addressList } from "@/api/address.js";
export default {
    namespaced: true,
    state: {
      address: {},
    },
    mutations: {
      setAddress(state, address) {
        state.address = address;
      },
    },
    actions: {
        // 初始化默认地址
        initAddress({commit, state}, reload=false) {
            return new Promise((resolve, reject)=>{
                if(JSON.stringify(state.address) === '{}' || reload){
                    addressList({}).then(res=>{
                        if(res.code == 0){
                            let index = res.data.findIndex(item=>item.is_default == 1);
                            if(index != -1){
                                commit("setAddress", res.data[index]);
                                resolve(state.address);
                            }else{
                                resolve({});
                            }
                        }
                    })
                }else{
                    resolve(state.address);
                }
            })
            
        },
    },
  };
  