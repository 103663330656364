import { task } from "@/lib/app-funs.js";
import { Toast } from "vant";

const PI = 3.1415926535897932384626
const a = 6378245.0
const ee = 0.00669342162296594323

const outOfChina = (lng, lat) => {
  return lng < 72.004 || lng > 137.8347 || lat < 0.8293 || lat > 55.8271 || false
}

function transformlat(lng, lat) {
  var ret =
    -100.0 +
    2.0 * lng +
    3.0 * lat +
    0.2 * lat * lat +
    0.1 * lng * lat +
    0.2 * Math.sqrt(Math.abs(lng))
  ret += ((20.0 * Math.sin(6.0 * lng * PI) + 20.0 * Math.sin(2.0 * lng * PI)) * 2.0) / 3.0
  ret += ((20.0 * Math.sin(lat * PI) + 40.0 * Math.sin((lat / 3.0) * PI)) * 2.0) / 3.0
  ret += ((160.0 * Math.sin((lat / 12.0) * PI) + 320 * Math.sin((lat * PI) / 30.0)) * 2.0) / 3.0
  return ret
}

function transformlng(lng, lat) {
  var ret =
    300.0 + lng + 2.0 * lat + 0.1 * lng * lng + 0.1 * lng * lat + 0.1 * Math.sqrt(Math.abs(lng))
  ret += ((20.0 * Math.sin(6.0 * lng * PI) + 20.0 * Math.sin(2.0 * lng * PI)) * 2.0) / 3.0
  ret += ((20.0 * Math.sin(lng * PI) + 40.0 * Math.sin((lng / 3.0) * PI)) * 2.0) / 3.0
  ret += ((150.0 * Math.sin((lng / 12.0) * PI) + 300.0 * Math.sin((lng / 30.0) * PI)) * 2.0) / 3.0
  return ret
}
export const Wgs84ToGcj02 = ({ lng, lat }) => {
    if (outOfChina(lng, lat)) {
      return { lng, lat }
    } else {
      var dlat = transformlat(lng - 105.0, lat - 35.0)
      var dlng = transformlng(lng - 105.0, lat - 35.0)
  
      var radlat = (lat / 180.0) * PI
      var magic = Math.sin(radlat)
      magic = 1 - ee * magic * magic
      var sqrtmagic = Math.sqrt(magic)
      dlat = (dlat * 180.0) / (((a * (1 - ee)) / (magic * sqrtmagic)) * PI)
      dlng = (dlng * 180.0) / ((a / sqrtmagic) * Math.cos(radlat) * PI)
      const mglat = lat + dlat
      const mglng = lng + dlng
      return { lng: mglng, lat: mglat }
    }
  }
  
  // 百度坐标转火星坐标
  export const Bd09ToGcj02 = (lng, lat) => {
    // eslint-disable-next-line no-loss-of-precision
    let x_pi = (3.14159265358979324 * 3000.0) / 180.0
    let Gcj02_point = { lng: 0, lat: 0 }
    let x = lng - 0.0065
    let y = lat - 0.006
    let z = Math.sqrt(x * x + y * y) - 0.00002 * Math.sin(y * x_pi)
    let theta = Math.atan2(y, x) - 0.000003 * Math.cos(x * x_pi)
    Gcj02_point.lng = z * Math.cos(theta)
    Gcj02_point.lat = z * Math.sin(theta)
    return Gcj02_point
  }
  
  // 火星坐标转百度坐标
  export const Gcj02ToBd09 = (mars_point) => {
    // eslint-disable-next-line no-loss-of-precision
    let x_pi = (3.14159265358979324 * 3000.0) / 180.0
    let baidu_point = { lng: 0, lat: 0 }
    let x = mars_point.lng
    let y = mars_point.lat
    let z = Math.sqrt(x * x + y * y) + 0.00002 * Math.sin(y * x_pi)
    let theta = Math.atan2(y, x) + 0.000003 * Math.cos(x * x_pi)
    baidu_point.lng = z * Math.cos(theta) + 0.0065
    baidu_point.lat = z * Math.sin(theta) + 0.006
    // baidu_point.lng = (z * Math.cos(theta) + 0.0065).toFixed(10)
    // baidu_point.lat = (z * Math.sin(theta) + 0.006).toFixed(10)
    return baidu_point
  }
export const getLocation = () =>{
  return new Promise((resolve, reject) => {
    task().then(loc=>{
      let point = { lng: loc.lng, lat: loc.lat };
      if (loc.type == 'WGS84') {
        point = Wgs84ToGcj02(point);
      }
      resolve(point);
    }).catch((errMsg) => {
      Toast('获取位置失败: ' + errMsg);
      resolve(null);
    })
  })
}