// 调用APP提供方法
const useAppFun = (action, data) => {
  return new Promise((resolve, reject) => {
    try {
      console.log(`调用${action}前，参数：`, data);
      window.skybirdFactory
        .task(action, data)
        .then((res) => {
          console.log(`调用${action}成功，返回：`, res);
          if (res) resolve(res);
          else resolve("success");
        })
        .catch((errMsg) => {
          console.log(`调用${action}失败，失败消息：`, errMsg);
          reject(errMsg);
        });
    } catch (error) {
      reject(error);
      console.log(`调用${action}失败，catchErr：`, error);
    }
  });
};

// 获取定位信息
export const task = (data = '') => {
  let action = 'getCurrentLocation'
  return useAppFun(action, data)
}

/**
 * 获取指定资源的缩略图, 如果是视频则返回视频第一帧的缩略图
 * @param {String} data 文件uri
 * @returns {String} res 图片的base64编码
 */
export const getThumbnail = async (data, withConsole) => {
  let action = "getThumbnail";
  return await useAppFun(action, data, withConsole);
};

// uri: uri,   //文件uri
// ossUrl: 'https://oss/xxx', //已签名的oss链接
// contentType: 'content-type', //http header中的Content-Type
// resize: 2048    //压缩后的最大边长, 0表示不压缩 (图片->png, 视频->mp4,h264编码)
/**
 * 上传指定资源
 * @param {
 *  uri: String,
 *  ossUrl: String,
 *  contentType
 *  resize
 * } data
 * @returns {res} String // 图片地址
 */
export const uploadMedia = (data) => {
  let action = "uploadMedia";
  return useAppFun(action, data, true);
};

/**
 * 调用相机
 * @param {duration} data
 * duration: 可选: 视频最大时长(秒)
 * @returns {
 * uri: "file:///storage/xxxx2",
 * size: 123456,
 * type: 'video',
 * ext: "mp4", //(jpg、png、、gif、heic、mp4、qov 等等)
 * duration: 12.5, //视频时长(秒)
 * } res
 */
export const camera = (data) => {
  let action = "camera";
  return useAppFun(action, data);
};

/**
 * 调用APP支付
 * @param {type, url} data // type: 'ali' || 'wx', url: '后端返回的已拼接完整的支付链接'
 * @returns
 */
export const pay = (data) => {
  let action = "pay";
  return useAppFun(action, data);
};

/**
 * 请求麦克风权限
 * @param {*} data 兼容问题, 无参数时请用空字符串
 * @returns
 */
export const microphoneAuthorization = (data) => {
  let action = "microphoneAuthorization";
  return useAppFun(action, data);
};
/**
 * 开始录音
 * @param {*} data 兼容问题, 无参数时请用空字符串
 * @returns
 */
export const startMicrophone = (data) => {
  let action = "startMicrophone";
  return useAppFun(action, data);
};
/**
 * 结束录音
 * @param {*} data 兼容问题, 无参数时请用空字符串
 * @returns {*} res
    // res = {
    //     uri: "安卓和苹果格式不一样,调用方当做是唯一key即可",
    //     size: 123456,
    //     duration: 12, //录音时长(秒) 四舍五入取整
    // }
 */
export const stopMicrophone = (data) => {
  let action = "stopMicrophone";
  return useAppFun(action, data);
};

/**
 * 检查文件是否存在
 * @param {*} data oss链接中的文件名，例如: 'uuid.pdf'
 * @returns
 */
export const fileExists = (data) => {
  let action = "fileExists";
  return useAppFun(action, data);
};
/**
 * 打开文件
 * @param {*} data oss链接中的文件名，例如: 'uuid.pdf'
 * @returns
 */
export const openFile = (data) => {
  let action = "openFile";
  return useAppFun(action, data);
};

// 获取地图app
export const getMapList = (data = '') => {
  let action = 'getMapList'
  return useAppFun(action, data)
}

/**
 * 下载/保存文件
 * @param {string} data.url 文件地址 https://oss文件链接
 * @param {string} data.type 'image' or 'video' or 'file'
 * @returns 
 */
export const download = (data) => {
  let action = 'download'
  return useAppFun(action, data)
}