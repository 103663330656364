let wx = require("weixin-js-sdk");
import { partConfig, errorLog } from "@/api/index";
import { location, locationsByIp } from "@/api/address.js";
import { isOnMobile } from "./utils";
import store from "../store";
import { Toast } from "vant";

export const config = (
  jsApiList = [
    "updateAppMessageShareData",
    "updateTimelineShareData",
    "startRecord",
    "stopRecord",
    "onVoiceRecordEnd",
    "playVoice",
    "pauseVoice",
    "stopVoice",
    "onVoicePlayEnd",
    "uploadVoice",
    "downloadVoice",
    "translateVoice",
    "getRecorderManager",
    "chooseImage",
    "previewImage",
    "uploadImage",
    "downloadImage",
    "getLocation",
    "openLocation",
  ]
) => {
  partConfig({ url: localStorage.getItem("APPFIRSTURL") ?? window.location.href.split("#")[0] }).then((res) => {
    wx.config({
      debug: false, // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印。
      appId: res.data.appId, // 必填，公众号的唯一标识
      timestamp: res.data.timestamp, // 必填，生成签名的时间戳
      nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
      signature: res.data.signature, // 必填，签名
      jsApiList: jsApiList, // 必填，需要使用的 JS 接口列表
    });
  });
};

export const share = async (data) => {
  await config();
  if (data) {
    wx.ready(function () {
      wx.updateAppMessageShareData({
        title: data.title,
        desc: data.desc,
        link: data.link,
        imgUrl: data.imgUrl,
        success: function () {},
      });
      wx.updateTimelineShareData({
        title: data.title,
        link: data.link,
        imgUrl: data.imgUrl,
        success: function () {},
      });
    });
  }
};

export const getWxLocation = async (loadConfig = false, cover = false) => {
  if (loadConfig) {
    await config();
  }
  wx.ready(function () {
    if (isOnMobile()) {
      // 手机端，用微信定位
      wx.getLocation({
        type: "gcj02", // 默认为wgs84的 gps 坐标，如果要返回直接给 openLocation 用的火星坐标，可传入'gcj02'
        success: function (res) {
          if (cover || store.state.wx.location.latitude == "0.0000000000") {
            var params = {
              is_default: 0,
            };
            if (res.latitude > 0) {
              params.latitude = res.latitude;
              params.longitude = res.longitude;
            }
            location(params).then((res) => {
              if (res.code == 0) {
                store.commit("wx/setLocation", {
                  latitude: params.latitude,
                  longitude: params.longitude,
                  title: res.data?.location?.recommend || "设置地址",
                  address: res.data?.location?.recommend || "设置地址",
                });
              }
            });
          }
        },
        cancel: function (res) {
          Toast({
            message: "您已拒绝定位授权",
            icon: "",
            duration: 2000,
            onClose: () => {
              errorLog({ text: `微信取消定位：${res.errMsg}` });
            },
          });
        },
        fail: function (res) {
          Toast({
            message: "微信定位失败,请稍后重试",
            icon: "",
            duration: 2000,
            onClose: () => {
              errorLog({ text: `微信定位错误：${res.errMsg}` });
            },
          });
        },
      });
    } else {
      // pc端用ip
      locationsByIp().then((res) => {
        if (res.code == 0) {
          if (cover || store.state.wx.location.latitude == "") {
            store.commit("wx/setLocation", {
              latitude: res.data.lat,
              longitude: res.data.lng,
              title: res.data.address || "设置地址",
              address: res.data.address || "设置地址",
            });
          }
        }
      });
    }
  });
};

export const openLocation = async (data) => {
  await config();
  if (data) {
    wx.openLocation({
      latitude: data.latitude, // 纬度，浮点数，范围为90 ~ -90
      longitude: data.longitude, // 经度，浮点数，范围为180 ~ -180。
      name: data.name, // 位置名
      address: data.address, // 地址详情说明
      scale: 1, // 地图缩放级别,整型值,范围从1~28。默认为最大
      infoUrl: "", // 在查看位置界面底部显示的超链接,可点击跳转
      success: function () {},
      fail: function (error) {
        console.log(error);
      },
    });
  }
};
