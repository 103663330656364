import Vue from "vue";
import VueRouter from "vue-router";
import { getToken, wxInfoLogin } from "@/lib/utils";
import store from "@/store";

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
Vue.use(VueRouter);
// showTabbar显示底部
//cache页面缓存
const routes = [
  {
    path: "/",
    name: "index",
    meta: {
      showTabbar: true,
      cache: true,
      title: "霄鸟云集采中心",
    },
    component: () => import("@/views/index/index.vue"),
  },
  {
    path: "/my",
    name: "my",
    meta: {
      showTabbar: true,
      title: "霄鸟云集采中心",
    },
    component: () => import("@/views/my/index.vue"),
  },
  {
    path: "/searchToy/:keyword?",
    name: "searchToy",
    meta: {
      cache: true,
      title: "搜索产品",
    },
    component: () => import("@/views/search/index.vue"),
  },
  // 原料商品
  {
    path: "/toy/detail",
    name: "toyDetail",
    meta: {
      title: "商品详情",
      cache: true,
    },
    component: () => import("@/views/toy/detail.vue"),
    children: [
      {
        path: "/toy/detail/address",
        name: "toyDetailShippingAddress",
        component: () => import("@/views/address/index.vue"),
      },
      {
        path: "/toy/detail/address/detail",
        name: "toyDetailAddressDetail",
        component: () => import("@/views/address/detail.vue"),
      },
    ],
  },
  // // 原料商品
  // {
  //   path: "/toy/detail",
  //   name: "toyDetail",
  //   meta: {
  //     title: "商品详情",
  //   },
  //   component: () => import("@/views/toy/detail2.vue"),
  // },
  {
    path: "/collect",
    name: "collect",
    meta: {
      cache: true,
      title: "商品收藏",
    },
    component: () => import("@/views/collect/index.vue"),
  },
  {
    path: "/browserRecord",
    name: "browserRecord",
    meta: {
      cache: true,
      title: "浏览记录",
    },
    component: () => import("@/views/browser-record/index.vue"),
  },
  {
    path: "/payRecord",
    name: "payRecord",
    meta: {
      cache: true,
      title: "支付明细",
    },
    component: () => import("@/views/pay-record/index.vue"),
  },
  // {
  //   path: "/order/add",
  //   name: "orderAdd",
  //   meta: {
  //     title: '填写订单'
  //   },
  //   component: () => import("@/views/order/add/index.vue"),
  //   children: [
  //     {
  //       path: "/order/add/address",
  //       name: "orderShippingAddress",
  //       component: () => import("@/views/address/index.vue"),
  //     },
  //     {
  //       path: "/order/add/address/detail",
  //       name: "orderAddressDetail",
  //       component: () => import("@/views/address/detail.vue"),
  //     },
  //     {
  //       path: "/order/add/invoice",
  //       name: "orderInvoice",
  //       component: () => import("@/views/invoice/index.vue"),
  //     },
  //     {
  //       path: "/order/add/invoice/detail",
  //       name: "orderInvoiceDetail",
  //       component: () => import("@/views/invoice/detail.vue"),
  //     },
  //   ]
  // },
  {
    path: "/order/add",
    name: "orderAdd",
    meta: {
      title: "填写订单",
    },
    component: () => import("@/views/order/add2/index.vue"),
    children: [
      {
        path: "/order/add/address",
        name: "orderShippingAddress",
        component: () => import("@/views/address/index.vue"),
      },
      {
        path: "/order/add/address/detail",
        name: "orderAddressDetail",
        component: () => import("@/views/address/detail.vue"),
      },
      {
        path: "/order/add/invoice",
        name: "orderInvoice",
        component: () => import("@/views/invoice/index.vue"),
      },
      {
        path: "/order/add/invoice/detail",
        name: "orderInvoiceDetail",
        component: () => import("@/views/invoice/detail.vue"),
      },
    ],
  },
  {
    path: "/order/:status?",
    name: "order",
    meta: {
      cache: true,
      title: "订单管理",
    },
    component: () => import("@/views/order/index/index.vue"),
  },
  {
    path: "/order/detail/:id",
    name: "orderDetail",
    meta: {
      title: "订单详情",
    },
    component: () => import("@/views/order/detail2/index.vue"),
  },
  {
    path: "/pay/result/:orderId",
    name: "payResult",
    meta: {},
    component: () => import("@/views/pay/result/index.vue"),
  },
  /* {
    path: "/pay/:orderId",
    name: "pay",
    meta: {

    },
    component: () => import("@/views/pay/index2/index.vue"),
  }, */
  {
    path: "/pay/voucher/:orderId",
    name: "payVoucher",
    meta: {
      title: "上传付款凭证",
    },
    component: () => import("@/views/pay/voucher/index.vue"),
  },
  {
    path: "/pay/batch/voucher",
    name: "payBatchVoucher",
    meta: {},
    component: () => import("@/views/pay/voucher/batch-index.vue"),
  },
  {
    path: "/auth",
    name: "auth",
    component: () => import("@/views/auth/index.vue"),
  },
  {
    path: "/invoice",
    name: "invoice",
    meta: {
      title: "发票抬头管理",
    },
    component: () => import("@/views/invoice/index.vue"),
  },
  {
    path: "/invoice/detail",
    name: "invoiceDetail",
    meta: {},
    component: () => import("@/views/invoice/detail.vue"),
  },
  {
    path: "/address",
    name: "shippingAddress",
    meta: {
      title: "收货地址管理",
    },
    component: () => import("@/views/address/index.vue"),
  },
  {
    path: "/address/detail",
    name: "addressDetail",
    component: () => import("@/views/address/detail.vue"),
  },
  {
    path: "/setting",
    name: "setting",
    meta: {
      title: "支付设置",
    },
    component: () => import("@/views/setting/index.vue"),
  },
  {
    path: "/setting/password",
    name: "setPassword",
    component: () => import("@/views/setting/password.vue"),
  },
  // {
  //   path: "/credit/apply",
  //   name: "creditApply",
  //   component: () => import("@/views/credit/apply.vue"),
  // },
  // {
  //   path: "/credit/record",
  //   name: "creditRecord",
  //   meta: {
  //     cache: true,
  //   },
  //   component: () => import("@/views/credit/record.vue"),
  // },
  {
    path: "/chat",
    name: "chat",
    meta: {
      showTabbar: true,
      title: "聊天消息",
    },
    component: () => import("@/views/chat/index.vue"),
  },
  {
    path: "/chat/detail",
    name: "chatDetail",
    component: () => import("@/views/chat/detail.vue"),
  },
  {
    path: "/integral",
    name: "integral",
    component: () => import("@/views/integral/index.vue"),
  },
  {
    path: "/customization",
    name: "customization",
    meta: {
      title: "我的定制",
      showTabbar: true,
      cache: true,
    },
    component: () => import("@/views/customization/index.vue"),
  },
  {
    path: "/customization/create",
    name: "customizationCreate",
    meta: {},
    component: () => import("@/views/customization/create.vue"),
    children: [
      {
        path: "/customization/add/address",
        name: "customizationShippingAddress",
        component: () => import("@/views/address/index.vue"),
      },
      {
        path: "/customization/add/address/detail",
        name: "customizationAddressDetail",
        component: () => import("@/views/address/detail.vue"),
      },
    ],
  },
  {
    path: "/customization/detail",
    name: "customizationDetail",
    meta: {
      title: "定制详情",
    },
    component: () => import("@/views/customization/detail/index.vue"),
  },
  {
    path: "/business-page",
    name: "business-page",
    component: () => import("@/views/business-Page/index.vue"),
  },
  {
    path: "/procurement",
    name: "procurement",
    meta: {
      showTabbar: true,
      title: "采购清单",
    },
    component: () => import("@/views/procurement/index.vue"),
    children: [
      {
        path: "/procurementAddress",
        name: "procurementAddress",
        component: () => import("@/views/address/index.vue"),
      },
      {
        path: "/procurementAddress/detail",
        name: "procurementAddressDetail",
        component: () => import("@/views/address/detail.vue"),
      },
    ],
  },
  { path: "*", redirect: "/" },
];

const router = new VueRouter({
  mode: "history",
  base: "/index",
  routes,
  scrollBehavior(to, from, savedPosition) {
    // 返回 savedPosition，在按下 后退/前进 按钮时，就会像浏览器的原生表现那样
    if (savedPosition) {
      return savedPosition;
    } else {
      // return 期望滚动到哪个的位置
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  localStorage.setItem("currentUrl", from.name);
  let whitelist = ["auth", "orderAdd", "pay"];
  if (to.name == "payResult") {
    return next();
  }
  let token = getToken();
  if (to.name != "auth" && !token) {
    return wxInfoLogin(to.fullPath);
  }

  //校验URL是不是除了_t参数之外一样
  let tmpTo = { ...to.query };
  let fromTo = { ...from.query };
  delete tmpTo["_t"];
  delete fromTo["_t"];
  let checkUrlEqually =
    to.name == from.name &&
    Object.entries(tmpTo).toString() == Object.entries(fromTo).toString() &&
    Object.entries(to.params).toString() ==
      Object.entries(from.params).toString();

  //时间戳，后退的from._t > to._t
  if (to.meta?.title) document.title = to.meta?.title;
  if (
    typeof to.query._t !== "undefined" ||
    whitelist.indexOf(to.name) != -1 ||
    checkUrlEqually
  ) {
    let newTo = { ...to };
    if (newTo?.query?._replace) {
      //有_replace参数就执行replace跳转，原replace()方法失效，要replace跳转一律用push价格query参数_replace=true
      newTo = { ...newTo, replace: true };
      delete newTo.query._replace;
      next(newTo);
    } else {
      if (to.name != "auth" && token && store.state.user.user == null) {
        store.dispatch('user/initUser', { reload: true, success: () => { }, err: () => { } });
      }
      next();
    }
  } else {
    let newTo = { ...to };
    newTo.query._t = new Date().getTime().toString();
    if (newTo?.query?._replace) {
      //有_replace参数就执行replace跳转，原replace()方法失效，要replace跳转一律用push价格query参数_replace=true
      newTo = { ...newTo, replace: true };
      delete newTo.query._replace;
    }
    next(newTo);
  }
});

router.afterEach((to, from, next) => {
  if (window._czc) {
    window._czc.push(["_trackPageview", to.path, from.path]);
    window._czc.push(["_setAutoPageview", false]);
  }
  // if (to.meta?.title) document.title = to.meta?.title;
});

export default router;
