export default {
  namespaced: true,
  state: {
    keyWord: "",
  },
  getters: {
    getKeyWord: (state) => state.keyWord,
  },
  mutations: {
    setKeyWord(state, keyWord) {
      state.keyWord = keyWord;
    },
  },
  actions: {
    doKeyWord(context, keyWord) {
      context.commit("setKeyWord", keyWord);
    },
  },
};
