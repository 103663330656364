export default {
  namespaced: true,
  state: {
    location:{
      latitude: '0.0000000000',
      longitude: '0.0000000000',
      address: '',
      title: ''
    }
  },
  getters: {
    getLocation(state) {
      return state.location
    }
  },
  mutations: {
    setLocation(state, location) {
      state.location = location;
    }
  },
  actions: {

  }
}